<template>
    <v-dialog v-model="dialog.open" persistent max-width="1000px">
        <v-card>
            <v-card-title>
                <span class="headline">管理TOKEN</span>
                <v-spacer></v-spacer>
                <v-btn @click="toAdd" color="primary">新增TOKEN</v-btn>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12" sm="6" md="6">
                            <v-text-field readonly v-model="dialog.item.code" label="CODE" dense></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <v-text-field readonly v-model="dialog.item.name" label="名称" dense></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                            <v-text-field readonly v-model="dialog.item.template" label="模板" dense></v-text-field>
                        </v-col>
                    </v-row>
                    <v-data-table :headers='headers' :items='dialog.list' :loading='dialog.loading'
                        no-data-text="还没有配置token" hide-default-footer>
                        <template #[`item.state`]='{ item }'>
                            <v-chip :color="emuns.statusColor[item.state]" small>{{ emuns.status[item.state]
                            }}</v-chip>
                        </template>
                        <template #[`item.actions`]='{ item }'>
                            <v-btn v-if="item.state != 1" @click="toDel(item)" icon small>
                                <v-icon small>{{ icons.mdiDelete }}</v-icon>
                            </v-btn>
                            <v-btn v-if="item.state == 1" @click="disable(item)" icon small>
                                <v-icon small>{{ icons.mdiCancel }}</v-icon>
                            </v-btn>
                            <v-btn v-else @click="enable(item)" icon small>
                                <v-icon small>{{ icons.mdiCheck }}</v-icon>
                            </v-btn>
                        </template>
                    </v-data-table>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" outlined @click="dialog.open = false"> 取消 </v-btn> <v-btn color="success"
                    @click="applyRefuse()">
                    提交 </v-btn>
            </v-card-actions>
        </v-card>

        <v-dialog v-model="dialogAdd.open" max-width="500px">
            <v-card>
                <v-card-title>
                    <span class="text-h6">添加TOKEN</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="12" md="12">
                                <v-text-field v-model="dialogAdd.content.content" label="Token"
                                    hint="请输入正确的token"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-switch v-model="dialogAdd.content.state" :true-value="1" :false-value="2"
                        :label="dialogAdd.content.state == 1 ? '当前状态：启用' : '当前状态：禁用'"></v-switch>
                    <v-spacer></v-spacer>
                    <v-btn color="grey" @click="dialogAdd.open = false"> 取消 </v-btn>
                    <v-btn color="primary" @click="add"> 提交 </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogDel.open" max-width="500px">
            <v-card>
                <v-card-title class="text-h5">确定要删除token[{{ dialogDel.content.content }}]？</v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="dialogDel.open = false">取消</v-btn>
                    <v-btn color="blue darken-1" text @click="del">确认</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-dialog>
</template>
<script>
import { mdiDelete, mdiCancel, mdiCheck } from '@mdi/js'
import { ref } from '@vue/composition-api'

export default {
    components: {},
    props: {
        dialog: {},
    },
    emits: ['onAddCommit', 'onDelCommit', 'onEnableCommit', 'onDisableCommit'],
    setup(props, { emit }) {
        const headers = [
            { text: '内容', value: 'content' },
            { text: '状态', value: 'state' },
            { text: '更新时间', value: 'update_at' },
            { text: '创建时间', value: 'create_at' },
            { text: '操作', value: 'actions', align: 'center', sortable: false }
        ]
        const emuns = {
            status: { 1: "启用", 2: "禁用" },
            statusColor: { 1: "success", 2: "error" },
        }

        const dialogDel = ref({
            open: false,
            content: { content: "", state: 1 }
        })
        const dialogAdd = ref({
            open: false, loading: false,
            content: { content: "", state: 1 }
        })

        const toAdd = () => {
            dialogAdd.value = {
                open: true, loading: false,
                content: { content: "", state: 1 }
            }
        }
        const add = () => {
            const data = {
                ...dialogAdd.value.content,
            }
            emit('onAddCommit', data)
            dialogAdd.value.open = false
            dialogAdd.value.loading = false
        }

        const toDel = (item) => {
            console.log(dialogDel.value);
            dialogDel.value = { open: true, content: item }
        }
        const del = () => {
            emit('onDelCommit', dialogDel.value.content.id)
            dialogDel.value.open = false
            dialogDel.value.loading = false
        }

        const enable = (item) => {
            emit('onEnableCommit', item.id)
        }
        const disable = (item) => {
            emit('onDisableCommit', item.id)
        }

        return {
            headers, emuns,
            toAdd, toDel,
            dialogDel, dialogAdd,
            add, del, enable, disable,
            icons: { mdiDelete, mdiCancel, mdiCheck },
        }
    },
} </script>
<style lang='scss'> @import '@core/preset/preset/apps/user.scss';
</style>
