import store from '@/store'
import { ref, watch } from '@vue/composition-api'

export default function useCacheTileList() {
    const headers = [
        { text: 'CODE', value: 'code' },
        { text: '名称', value: 'name' },
        // { text: '模板', value: 'template' },
        // { text: '链接', value: 'url' },
        { text: '格式', value: 'format' },
        { text: '内存缓存', value: 'memory_size' },
        { text: '内存过期', value: 'memory_overdue' },
        { text: '磁盘缓存', value: 'disk_size' },
        { text: '磁盘过期', value: 'disk_overdue' },
        // { text: '创建时间', value: 'create_at' },
        { text: '状态', value: 'state' },
        { text: '操作', value: 'actions', align: 'center', sortable: false }
    ]
    const param = ref({
        query: '',
        options: { sortBy: ['id'], sortDesc: [true], page: 1, itemsPerPage: 10 }
    })
    const enums = {
        status: { "1": "启用", "2": "禁用" },
        statusColor: { "1": "success", "2": "error" },
    }
    const table = ref({
        list: [],
        total: 0,
        snak: { open: false, msg: '' },
        lodading: false,
        dialogToken: {
            open: false,
            loading: false,
            item: {},
            content: {},
            list: [],
        },
        dialogAdd: {
            open: false,
            loading: false,
            content: { code: '', name: '', format: '', template: '', memory_size: '', memory_overdue: '', disk_size: '', disk_overdue: '', state: 1 },
        },
        dialogEdit: {
            open: false,
            loading: false,
            content: { code: '', name: '', format: '', template: '', memory_size: 0, memory_overdue: 0, disk_size: 0, disk_overdue: 0, state: 2 },
        },
        dialogRemove: {
            open: false,
            loading: false,
            content: { code: '', name: '', format: '', template: '', memory_size: 0, memory_overdue: 0, disk_size: 0, disk_overdue: 0, state: 2 },
        }
    })
    const toManageToken = (item) => {
        table.value.dialogToken.loading = true
        store.dispatch('app-cache-tile/fetchTileCacheTokenList', { tile_cache_id: item.id, state: 0 }).then(res => {
            const { code, data, msg } = res.data
            if (code !== 200) {
                table.value = { open: true, msg: `错误码：${code}, ${msg}` }
                return
            }
            table.value.dialogToken = {
                open: true,
                item: item,
                list: data || [],
                content: {},
            }
        }).catch(error => { table.value.snak = { open: true, msg: `网络错误: ${error}` } }).finally(table.value.dialogToken.loading = false)
    }
    const fetchTileCachePage = () => {
        table.value.loading = true
        store.dispatch('app-cache-tile/fetchTileCachePage', param.value).then(res => {
            const { code, msg } = res.data
            if (code !== 200) {
                table.value = { open: true, msg: `错误码：${code}, ${msg}` }
                return
            }
            const { list, total } = res.data.data
            table.value.list = list || []
            table.value.total = total
        }).catch(error => { table.value.snak = { open: true, msg: `网络错误: ${error}` } }).finally(table.value.loading = false)
    }
    const toAdd = () => {
        table.value.dialogAdd = {
            open: true,
            loading: false,
            content: { code: '', name: '', format: '', template: '', memory_size: 0, memory_overdue: 0, disk_size: 0, disk_overdue: 0, state: 2 },
        }
    }
    const tileCacheAdd = () => {
        table.value.dialogAdd.loading = true
        const data = { ...table.value.dialogAdd.content }
        data.state = parseInt(data.state)
        data.memory_size = parseInt(data.memory_size)
        data.memory_overdue = parseInt(data.memory_overdue)
        data.disk_size = parseInt(data.disk_size)
        data.disk_overdue = parseInt(data.disk_overdue)
        store.dispatch('app-cache-tile/tileCacheAdd', data).then(res => {
            const { code, msg } = res.data
            if (code !== 200) {
                table.value = { open: true, msg: `错误码：${code}, ${msg}` }
                return
            }
            fetchTileCachePage()
            table.value.dialogAdd.open = false
        }).catch(error => { table.value.snak = { open: true, msg: `网络错误: ${error}` } }).finally(table.value.dialogAdd.loading = false)
    }
    const toEdit = (item) => {
        table.value.dialogEdit = {
            open: true,
            loading: false,
            content: item,
        }
    }
    const tileCacheUpdate = () => {
        table.value.dialogEdit.loading = true
        const data = { ...table.value.dialogEdit.content }
        data.state = parseInt(data.state)
        data.memory_size = parseInt(data.memory_size)
        data.memory_overdue = parseInt(data.memory_overdue)
        data.disk_size = parseInt(data.disk_size)
        data.disk_overdue = parseInt(data.disk_overdue)
        store.dispatch('app-cache-tile/tileCacheUpdate', data).then(res => {
            const { code, msg } = res.data
            if (code !== 200) {
                table.value = { open: true, msg: `错误码：${code}, ${msg}` }
                return
            }
            fetchTileCachePage()
            table.value.dialogEdit.open = false
        }).catch(error => { table.value.snak = { open: true, msg: `网络错误: ${error}` } }).finally(table.value.dialogEdit.loading = false)
    }
    const toRemove = (item) => {
        table.value.dialogRemove = {
            open: true,
            loading: false,
            content: item,
        }
    }
    const tileCacheRemove = () => {
        table.value.dialogRemove.loading = true
        const data = { ...table.value.dialogRemove.content }
        store.dispatch('app-cache-tile/tileCacheRemove', data).then(res => {
            const { code, msg } = res.data
            if (code !== 200) {
                table.value = { open: true, msg: `错误码：${code}, ${msg}` }
                return
            }
            fetchTileCachePage()
            table.value.dialogRemove.open = false
        }).catch(error => { table.value.snak = { open: true, msg: `网络错误: ${error}` } }).finally(table.value.dialogRemove.loading = false)
    }

    const tileCacheTokenAdd = (data) => {
        data.tile_cache_id = table.value.dialogToken.item.id
        store.dispatch('app-cache-tile/tileCacheTokenAdd', data).then(res => {
            const { code, msg } = res.data
            if (code !== 200) {
                table.value = { open: true, msg: `错误码：${code}, ${msg}` }
                return
            }
            toManageToken(table.value.dialogToken.item)
        }).catch(error => { table.value.snak = { open: true, msg: `网络错误: ${error}` } }).finally(table.value.dialogToken.loading = false)
    }
    const tileCacheTokenDel = (id) => {
        store.dispatch('app-cache-tile/tileCacheTokenDel', id).then(res => {
            const { code, msg } = res.data
            if (code !== 200) {
                table.value = { open: true, msg: `错误码：${code}, ${msg}` }
                return
            }
            toManageToken(table.value.dialogToken.item)
        }).catch(error => { table.value.snak = { open: true, msg: `网络错误: ${error}` } }).finally(table.value.dialogToken.loading = false)
    }
    const tileCacheTokenEnable = (id) => {
        store.dispatch('app-cache-tile/tileCacheTokenEnable', id).then(res => {
            const { code, msg } = res.data
            if (code !== 200) {
                table.value = { open: true, msg: `错误码：${code}, ${msg}` }
                return
            }
            toManageToken(table.value.dialogToken.item)
        }).catch(error => { table.value.snak = { open: true, msg: `网络错误: ${error}` } }).finally(table.value.dialogToken.loading = false)
    }
    const tileCacheTokenDisable = (id) => {
        store.dispatch('app-cache-tile/tileCacheTokenDisable', id).then(res => {
            const { code, msg } = res.data
            if (code !== 200) {
                table.value = { open: true, msg: `错误码：${code}, ${msg}` }
                return
            }
            toManageToken(table.value.dialogToken.item)
        }).catch(error => { table.value.snak = { open: true, msg: `网络错误: ${error}` } }).finally(table.value.dialogToken.loading = false)
    }

    watch([param], () => {
        fetchTileCachePage()
    }, { deep: true })
    return {
        headers,
        param,
        table, enums,
        toAdd, tileCacheAdd, toEdit, tileCacheUpdate, toRemove, tileCacheRemove,
        toManageToken,
        fetchTileCachePage,
        tileCacheTokenAdd,
        tileCacheTokenDel,
        tileCacheTokenEnable,
        tileCacheTokenDisable,
    }
}
