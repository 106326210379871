<template>
    <div id='tile-cache-list'>
        <v-dialog v-model="table.dialogAdd.open" max-width="800px">
            <v-card>
                <v-card-title>
                    <span class="text-h6">添加TOKEN</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                                <v-text-field v-model="table.dialogAdd.content.code" label="code"
                                    hint="新建后不可修改"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                                <v-text-field v-model="table.dialogAdd.content.name" label="名称" hint="配置的名称"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                                <v-text-field v-model="table.dialogAdd.content.format" label="格式"
                                    hint="WEBP PNG JPG"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                                <v-text-field v-model="table.dialogAdd.content.template" label="模板"
                                    hint="如：https://tiles1.geovisearth.com/base/v1/img/{z}/{x}/{y}?format={format}&tmsIds=w"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                                <v-text-field v-model="table.dialogAdd.content.memory_size" type="number" label="内存缓存限制"
                                    hint="内存缓存限制，瓦片数量，0为不限制"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                                <v-text-field v-model="table.dialogAdd.content.memory_overdue" type="number" label="内存缓存有效期"
                                    hint="内存缓存有效期，单位秒，0为不限制"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                                <v-text-field v-model="table.dialogAdd.content.disk_size" type="number" label="磁盘缓存限制"
                                    hint="磁盘缓存限制，瓦片数量，0为不限制"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                                <v-text-field v-model="table.dialogAdd.content.disk_overdue" type="number" label="磁盘缓存有效期"
                                    hint="磁盘缓存有效期，单位天，0为不限制"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-switch v-model="table.dialogAdd.content.state" :true-value="1" :false-value="2"
                        :label="table.dialogAdd.content.state == 1 ? '当前状态：启用' : '当前状态：禁用'"></v-switch>
                    <v-spacer></v-spacer>
                    <v-btn color="grey" @click="table.dialogAdd.open = false"> 取消 </v-btn>
                    <v-btn color="primary" @click="tileCacheAdd" :loading="table.dialogAdd.loading"> 提交 </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="table.dialogEdit.open" max-width="800px">
            <v-card>
                <v-card-title>
                    <span class="text-h6">编辑TOKEN</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                                <v-text-field readonly v-model="table.dialogEdit.content.code" label="code"
                                    hint="新建后不可修改"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                                <v-text-field v-model="table.dialogEdit.content.name" label="名称"
                                    hint="配置的名称"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                                <v-text-field readonly v-model="table.dialogEdit.content.format" label="格式"
                                    hint="WEBP PNG JPG"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                                <v-text-field readonly v-model="table.dialogEdit.content.template" label="模板"
                                    hint="如：https://tiles1.geovisearth.com/base/v1/img/{z}/{x}/{y}?format={format}&tmsIds=w"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                                <v-text-field v-model="table.dialogEdit.content.memory_size" type="number" label="内存缓存限制"
                                    hint="内存缓存限制，瓦片数量，0为不限制"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                                <v-text-field v-model="table.dialogEdit.content.memory_overdue" type="number"
                                    label="内存缓存有效期" hint="内存缓存有效期，单位秒，0为不限制"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                                <v-text-field v-model="table.dialogEdit.content.disk_size" type="number" label="磁盘缓存限制"
                                    hint="磁盘缓存限制，瓦片数量，0为不限制"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                                <v-text-field v-model="table.dialogEdit.content.disk_overdue" type="number" label="磁盘缓存有效期"
                                    hint="磁盘缓存有效期，单位天，0为不限制"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-switch v-model="table.dialogEdit.content.state" :true-value="1" :false-value="2"
                        :label="table.dialogEdit.content.state == 1 ? '当前状态：启用' : '当前状态：禁用'"></v-switch>
                    <v-spacer></v-spacer>
                    <v-btn color="grey" @click="table.dialogEdit.open = false"> 取消 </v-btn>
                    <v-btn color="primary" @click="tileCacheUpdate"> 提交 </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="table.dialogRemove.open" max-width="800px">
            <v-card>
                <v-card-title>
                    <span class="text-h6">删除TOKEN</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                                <v-text-field readonly v-model="table.dialogRemove.content.code" label="code"
                                    hint="新建后不可修改"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                                <v-text-field v-model="table.dialogRemove.content.name" label="名称"
                                    hint="配置的名称"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                                <v-text-field readonly v-model="table.dialogRemove.content.format" label="格式"
                                    hint="WEBP PNG JPG"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                                <v-text-field readonly v-model="table.dialogRemove.content.template" label="模板"
                                    hint="如：https://tiles1.geovisearth.com/base/v1/img/{z}/{x}/{y}?format={format}&tmsIds=w"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                                <v-text-field v-model="table.dialogRemove.content.memory_size" type="number" label="内存缓存限制"
                                    hint="内存缓存限制，瓦片数量，0为不限制"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                                <v-text-field v-model="table.dialogRemove.content.memory_overdue" type="number"
                                    label="内存缓存有效期" hint="内存缓存有效期，单位秒，0为不限制"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                                <v-text-field v-model="table.dialogRemove.content.disk_size" type="number" label="磁盘缓存限制"
                                    hint="磁盘缓存限制，瓦片数量，0为不限制"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                                <v-text-field v-model="table.dialogRemove.content.disk_overdue" type="number"
                                    label="磁盘缓存有效期" hint="磁盘缓存有效期，单位天，0为不限制"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-switch v-model="table.dialogRemove.content.state" :true-value="1" :false-value="2"
                        :label="table.dialogEdit.content.state == 1 ? '当前状态：启用' : '当前状态：禁用'"></v-switch>
                    <v-spacer></v-spacer>
                    <v-btn color="grey" @click="table.dialogRemove.open = false"> 取消 </v-btn>
                    <v-btn color="error" @click="tileCacheRemove"> 删除 </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogShowUrl.open" max-width="800px">
            <v-card>
                <v-card-title>
                    <span class="text-h6">删除TOKEN</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row dense>
                            <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                                <v-text-field readonly v-model="dialogShowUrl.content.code" label="code" hint="新建后不可修改"
                                    hide-details></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                                <v-text-field readonly v-model="dialogShowUrl.content.name" label="名称" hint="配置的名称"
                                    hide-details></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                                <v-text-field readonly v-model="dialogShowUrl.content.format" label="格式" hint="WEBP PNG JPG"
                                    hide-details></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                                <v-text-field readonly v-model="dialogShowUrl.content.template" label="模板"
                                    hint="如：https://tiles1.geovisearth.com/base/v1/img/{z}/{x}/{y}?format={format}&tmsIds=w"
                                    hide-details></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                                <v-text-field readonly v-model="dialogShowUrl.content.url" label="使用链接"
                                    hint="如：https://tiles1.geovisearth.com/base/v1/img/{z}/{x}/{y}?format={format}&tmsIds=w"
                                    hide-details></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                                <v-text-field readonly v-model="dialogShowUrl.content.memory_size" type="number"
                                    label="内存缓存限制" hint="内存缓存限制，瓦片数量，0为不限制" hide-details></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                                <v-text-field readonly v-model="dialogShowUrl.content.memory_overdue" type="number"
                                    label="内存缓存有效期" hint="内存缓存有效期，单位秒，0为不限制" hide-details></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                                <v-text-field readonly v-model="dialogShowUrl.content.disk_size" type="number"
                                    label="磁盘缓存限制" hint="磁盘缓存限制，瓦片数量，0为不限制" hide-details></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                                <v-text-field readonly v-model="dialogShowUrl.content.disk_overdue" type="number"
                                    label="磁盘缓存有效期" hint="磁盘缓存有效期，单位天，0为不限制" hide-details></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="grey" @click="dialogShowUrl.open = false"> 关闭 </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <CacheTileToken :dialog="table.dialogToken" @onAddCommit="tileCacheTokenAdd" @onDelCommit="tileCacheTokenDel"
            @onEnableCommit="tileCacheTokenEnable" @onDisableCommit="tileCacheTokenDisable" />
        <v-snackbar v-model="table.snak.open" multi-line :light="$vuetify.theme.dark" :timeout="2000">
            {{ table.snak.msg }}
            <template #action="{ attrs }">
                <v-btn color="error" text v-bind="attrs" @click="table.snak.open = false"> 关闭 </v-btn>
            </template>
        </v-snackbar>
        <v-card>
            <v-divider class='mt-4'></v-divider>
            <v-card-text class='d-flex align-center flex-wrap pb-4'>
                <v-text-field v-model='param.query' class='user-search me-3 mb-0' dense hide-details outlined
                    placeholder='搜索'></v-text-field>
                <v-spacer></v-spacer>
                <v-btn @click="toAdd" color="primary">新增</v-btn>
            </v-card-text>
            <!-- table -->
            <v-data-table :headers='headers' :items='table.list' :loading='table.loading' :options.sync='param.options'
                :server-items-length='table.total'>
                <!-- actions -->
                <template #[`item.state`]='{ item }'>
                    <v-chip :color="enums.statusColor[item.state]" small>{{ enums.status[item.state] }}</v-chip>
                </template>
                <template #[`item.actions`]='{ item }'>
                    <v-menu bottom left>
                        <template v-slot:activator='{ on, attrs }'>
                            <v-btn icon v-bind='attrs' v-on='on'>
                                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item>
                                <v-list-item-title>
                                    <v-btn @click="toEdit(item)" color="primary" small>编辑</v-btn>
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item v-if="item.state != 1">
                                <v-list-item-title>
                                    <v-btn @click="toRemove(item)" color="error" small>删除</v-btn>
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-title>
                                    <v-btn @click="toShow(item)" color="info" small>查看详情</v-btn>
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-title>
                                    <v-btn :loading="table.dialogToken.loading" @click="toManageToken(item)" color="warning"
                                        small>TOKEN管理</v-btn>
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>
<script>
// eslint-disable-next-line object-curly-newline
import { mdiAccountOutline, mdiDeleteOutline, mdiDotsVertical, mdiExportVariant, mdiPencil, mdiPlus, mdiSquareEditOutline, } from '@mdi/js'
import CacheTileToken from './CacheTileToken.vue'
import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'
import cacheTileStore from '../cacheTileStore'
import useCacheTileList from './useCacheTileList'

export default {
    components: { CacheTileToken },
    setup() {
        const USER_APP_STORE_MODULE_NAME = 'app-cache-tile'
        if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, cacheTileStore)
        onUnmounted(() => {
            if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
        })
        const { headers, param, table, enums, toAdd, tileCacheAdd, toEdit, tileCacheUpdate, toRemove, tileCacheRemove, toManageToken, tileCacheTokenAdd, tileCacheTokenDel, tileCacheTokenEnable, tileCacheTokenDisable, fetchTileCachePage } = useCacheTileList()

        const dialogShowUrl = ref({ open: false, content: {} })

        const toShow = (item) => {
            dialogShowUrl.value = { open: true, content: item }
        }

        return {
            headers,
            param,
            table, enums,
            dialogShowUrl, toShow,
            toAdd, tileCacheAdd, toEdit, tileCacheUpdate, toRemove, tileCacheRemove,
            toManageToken, tileCacheTokenAdd, tileCacheTokenDel, tileCacheTokenEnable, tileCacheTokenDisable,
            fetchTileCachePage,
            icons: { mdiSquareEditOutline, mdiPencil, mdiDotsVertical, mdiDeleteOutline, mdiPlus, mdiExportVariant, mdiAccountOutline, },
        }
    },
} </script>
<style lang='scss'> @import '@core/preset/preset/apps/user.scss';
</style>
