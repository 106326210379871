import axios from '@axios'

export default {
    namespaced: true, state: {}, getters: {}, mutations: {}, actions: {

        fetchTileCachePage(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios.get(`/cache/v1/tile-cache/${queryParams.options.page}/${queryParams.options.itemsPerPage}`, { params: queryParams }).then(response => resolve(response)).catch(error => reject(error))
            })
        },
        tileCacheAdd(ctx, data) {
            return new Promise((resolve, reject) => {
                axios.post(`/cache/v1/tile-cache`, data).then(response => resolve(response)).catch(error => reject(error))
            })
        },
        tileCacheUpdate(ctx, data) {
            return new Promise((resolve, reject) => {
                axios.put(`/cache/v1/tile-cache/${data.id}`, data).then(response => resolve(response)).catch(error => reject(error))
            })
        },
        tileCacheRemove(ctx, data) {
            return new Promise((resolve, reject) => {
                axios.delete(`/cache/v1/tile-cache/${data.id}`).then(response => resolve(response)).catch(error => reject(error))
            })
        },

        fetchTileCacheTokenList(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios.get(`/cache/v1/tile-cache/token/list`, { params: queryParams }).then(response => resolve(response)).catch(error => reject(error))
            })
        },
        tileCacheTokenAdd(ctx, data) {
            return new Promise((resolve, reject) => {
                axios.post(`/cache/v1/tile-cache/token`, data).then(response => resolve(response)).catch(error => reject(error))
            })
        },
        tileCacheTokenDel(ctx, id) {
            return new Promise((resolve, reject) => {
                axios.delete(`/cache/v1/tile-cache/token/${id}`).then(response => resolve(response)).catch(error => reject(error))
            })
        },
        tileCacheTokenEnable(ctx, id) {
            return new Promise((resolve, reject) => {
                axios.put(`/cache/v1/tile-cache/token/enable/${id}`).then(response => resolve(response)).catch(error => reject(error))
            })
        },
        tileCacheTokenDisable(ctx, id) {
            return new Promise((resolve, reject) => {
                axios.put(`/cache/v1/tile-cache/token/disable/${id}`).then(response => resolve(response)).catch(error => reject(error))
            })
        },
    },
}
